import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'types/App';
import { ProductListParams } from 'types/Playlist';
import { ProductItem } from 'types/Product';
import { Drawer } from 'views/components/compound';
import SelectedContents from './SelectedContents';
import ProductContentList from './ProductContentList';
import SearchArea from './SearchArea';
import { typesFromFilterOptions } from '../FileTypeButton';
import styled from '@emotion/styled';

type Props = {
  contentIds: number[];
  originalContentIds?: number[];
  productContentList: ProductItem[];
  open: boolean;
  toggleOpen: () => void;
  getProductContentList: (
    args: ProductListParams
  ) => Promise<
    | {
        product_contents: ProductItem[];
        total: number;
        organization_id: string;
        perPage?: number;
      }
    | undefined
  >;
  defaultLoadingContentNumber: number;
  idProductContent: { [id: string]: ProductItem };
  tags?: Tag[];
  isParentComponentOpen: boolean;
  updateContentIds: (contentIds: number[]) => void;
  loading: boolean;
  isEditMode?: boolean;
};

const SelectProductContentsDrawer: FC<Props> = ({
  contentIds,
  productContentList,
  open,
  toggleOpen,
  getProductContentList,
  defaultLoadingContentNumber,
  idProductContent,
  tags,
  isParentComponentOpen,
  updateContentIds,
  loading,
  originalContentIds,
  isEditMode,
}) => {
  const { t } = useTranslation();
  const [selectedContentIds, setSelectedContentIds] = useState<number[]>(contentIds);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentTagIds, setCurrentTagIds] = useState<number[]>([]);
  const [currentType, setCurrentType] = useState('all');
  const fileTypes = typesFromFilterOptions(currentType);
  const selectedContents =
    Object.keys(idProductContent).length !== 0
      ? selectedContentIds.map(id => idProductContent[id])
      : [];
  const disabledSave = selectedContentIds.length === 0;

  useEffect(() => {
    setSelectedContentIds(contentIds);
  }, [contentIds]);

  useEffect(() => {
    if (!isParentComponentOpen) {
      setSearchKeyword('');
      setCurrentTagIds([]);
      setCurrentType('all');
    } else {
      getProductContentList({ q: searchKeyword, tags: currentTagIds, types: fileTypes });
    }
  }, [isParentComponentOpen]);

  const handleSetSearchKeyword = (value: string) => {
    if (searchKeyword !== value) {
      setSearchKeyword(value);
    }
  };

  const handleSearch = () => {
    getProductContentList({ q: searchKeyword, tags: currentTagIds, types: fileTypes });
  };

  const onSelectTags = (tags: Tag[]) => {
    if (tags.length > 0 || currentTagIds.length > 0) {
      const tagIds = tags.map(t => t.id);
      setCurrentTagIds(tagIds);
      getProductContentList({ q: searchKeyword, tags: tagIds, types: fileTypes });
    }
  };

  const handleFilter = (typeParams: string) => {
    setCurrentType(typeParams);
    const types = typesFromFilterOptions(typeParams);
    getProductContentList({ q: searchKeyword, tags: currentTagIds, types });
  };

  const selectAllContents = () =>
    setSelectedContentIds(
      Array.from(
        new Set(
          productContentList
            .map(pc => pc.id)
            .concat(selectedContentIds)
            .sort((a, b) => a - b)
        )
      )
    );

  const releaseAllContents = () => setSelectedContentIds([]);

  const toggleSelected = (productContentId: number) => {
    if (selectedContentIds.includes(productContentId)) {
      setSelectedContentIds(ids => ids.filter(contentId => contentId !== productContentId));
    } else {
      setSelectedContentIds([...selectedContentIds, productContentId]);
    }
  };

  const handleClose = () => {
    setSelectedContentIds(contentIds);
    toggleOpen();
  };

  const handleSelectContents = () => {
    updateContentIds(selectedContentIds);
    toggleOpen();
  };

  return (
    <Drawer
      disabledAction={disabledSave}
      isOpen={open}
      title={t('hearingPlay.result.addProduct')}
      onClose={handleClose}
      onSave={handleSelectContents}
      saveButtonLabel={t('common.select2')}
    >
      <DrawerInnerWrapper>
        <SearchArea
          handleSearch={handleSearch}
          searchKeyword={searchKeyword}
          tags={tags}
          handleFilter={handleFilter}
          currentType={currentType}
          currentTagIds={currentTagIds}
          onSelectTags={onSelectTags}
          selectAll={selectAllContents}
          releaseAll={releaseAllContents}
          handleSetSearchKeyword={handleSetSearchKeyword}
        />
        <ProductContentList
          isEditMode={isEditMode}
          originalContentIds={originalContentIds}
          loading={loading}
          contentList={productContentList}
          toggleSelected={toggleSelected}
          selectedContentIds={selectedContentIds}
          defaultLoadingContentNumber={defaultLoadingContentNumber}
          loadMoreContents={page => {
            return getProductContentList({
              q: searchKeyword,
              tags: currentTagIds,
              types: fileTypes,
              concat: true,
              page,
            });
          }}
        />
        <SelectedContents selectedContents={selectedContents} />
      </DrawerInnerWrapper>
    </Drawer>
  );
};

export default SelectProductContentsDrawer;

const DrawerInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
});
