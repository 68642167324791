import React, { FC, useState } from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import {
  ConfirmModal,
  Drawer,
  DeleteButton,
  SelectProductContentsDrawer,
} from 'views/components/compound';
import { getImgFromContent } from 'utility/helpers';
import { InputText as _InputText, Button } from 'views/components/primitive';
import ThumbnailIcon from '../ThumbnailIcon';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useCurrentOrganization } from 'organisms/useCurrentOrganization';
import { useEditProductContents } from 'organisms/Admin/ContentManagement/usePlaylistContent';

type Props = {
  isOpen: boolean;
  handleCloseEditTextDrawer: () => void;
};

const EditPlaylistContentDrawer: FC<Props> = ({ isOpen, handleCloseEditTextDrawer }) => {
  const { t } = useTranslation();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [isSelectContentDrawerOpen, setSelectContentDrawerOpen] = useState(false);

  const {
    playlistStore: {
      productContentList,
      getProductContentList,
      loadingProductList,
      idProductContent,
      defaultLoadingContentNumber,
    },
  } = useStore();

  const {
    productContents,
    deleteContent,
    updateSelectedContents,
    handleChangeDisplayedText,
    productContentIds,
    handleSaveContentList,
    resetProductContents,
    isPlaylistChanged,
  } = useEditProductContents();

  const currentOrganization = useCurrentOrganization();

  const toggleConfirmModal = () => setOpenConfirmModal(!isOpenConfirmModal);

  const toggleSelectContentDrawer = () => setSelectContentDrawerOpen(!isSelectContentDrawerOpen);

  const handleClickCancelButton = () => {
    if (isPlaylistChanged) {
      setOpenConfirmModal(true);
    } else {
      handleCloseEditTextDrawer();
    }
  };

  const handleConfirmCancel = () => {
    resetProductContents();
    handleCloseEditTextDrawer();
  };

  const handleSavePlaylist = () => {
    handleSaveContentList();
    handleCloseEditTextDrawer();
  };

  return (
    <>
      <Drawer
        disabledAction={!isPlaylistChanged || productContents.length <= 0}
        isOpen={isOpen}
        onClose={handleClickCancelButton}
        title={t('admin.playlist.playlistSetting.drawerTitle')}
        onSave={handleSavePlaylist}
        isUpperDrawerOpen={isSelectContentDrawerOpen}
      >
        <ButtonWrapper>
          <AddContentButton
            type="default"
            size="sm"
            label={t('admin.playlist.createPlaylistDrawer.selectContents')}
            onClick={toggleSelectContentDrawer}
          />
        </ButtonWrapper>
        <Instruction>{t('admin.playlist.playlistSetting.instruction')}</Instruction>
        <Playlist>
          {productContents.map(content => {
            const thumbnail = getImgFromContent(content);

            const handleChangeText = (displayedText: string) => {
              handleChangeDisplayedText(displayedText, content.id);
            };

            const displayedText = content.playlist_title;

            const handleDeleteContent = () => deleteContent(content.id);

            return (
              <PlaylistItem key={content.id}>
                <ThumbnailIcon src={thumbnail} size={144} aspectRatio={16 / 9} />
                <InfoWrapper>
                  <TitleWrapper>
                    <Title>{content.title}</Title>
                    <DeleteButton isBackground size="sm" onClick={handleDeleteContent} />
                  </TitleWrapper>
                  <InputDisplayTextWrapper>
                    <InputTextLabel>
                      {t('admin.playlist.playlistSetting.displayedText')}:
                    </InputTextLabel>
                    <InputText
                      value={displayedText || content.title}
                      onChange={handleChangeText}
                      maxLength={80}
                    />
                  </InputDisplayTextWrapper>
                </InfoWrapper>
              </PlaylistItem>
            );
          })}
        </Playlist>
      </Drawer>
      <ConfirmModal
        isModal={isOpenConfirmModal}
        toggleModal={toggleConfirmModal}
        handleConfirmModal={handleConfirmCancel}
      />
      <SelectProductContentsDrawer
        defaultLoadingContentNumber={defaultLoadingContentNumber}
        originalContentIds={productContentIds}
        contentIds={productContentIds}
        productContentList={productContentList}
        open={isSelectContentDrawerOpen}
        toggleOpen={toggleSelectContentDrawer}
        getProductContentList={getProductContentList}
        idProductContent={idProductContent}
        tags={currentOrganization?.tags || []}
        isParentComponentOpen={isOpen}
        updateContentIds={updateSelectedContents}
        loading={loadingProductList}
        isEditMode
      />
    </>
  );
};

export default observer(EditPlaylistContentDrawer);

const Instruction = styled('p')({
  whiteSpace: 'pre-line',
  fontSize: '0.75rem',
  marginBottom: 16,
});

const Playlist = styled('ul')({
  listStyle: 'none',
});

const PlaylistItem = styled('li')({
  marginBottom: 16,
  display: 'flex',
  width: '100%',
});

const InputText = styled(_InputText)({
  marginBottom: 0,
  width: '100%',
});

const AddContentButton = styled(Button)({});

const ButtonWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 12,
});

const InputDisplayTextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const InputTextLabel = styled('span')({
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  fontSize: '0.875rem',
  marginRight: 8,
});

const Title = styled('p')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  width: '100%',
});

const InfoWrapper = styled('div')({
  width: '100%',
});

const TitleWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  marginBottom: 4,
});
