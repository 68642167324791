import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import { ProductItem } from 'types/Product';
import _ProductInfo from '../ProductInfo';
import { Button, Checkbox as _Checkbox } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator';

type Props = {
  contentList: ProductItem[];
  selectedContentIds: number[];
  originalContentIds?: number[];
  isEditMode?: boolean;
  toggleSelected: (contentId: number) => void;
  loading: boolean;
  defaultLoadingContentNumber: number;
  loadMoreContents: (
    loadPageNumber: number
  ) => Promise<
    | {
        product_contents: ProductItem[];
        total: number;
        organization_id: string;
        perPage?: number;
      }
    | undefined
  >;
};

const ProductContentList: FC<Props> = ({
  contentList,
  selectedContentIds,
  toggleSelected,
  loadMoreContents,
  defaultLoadingContentNumber,
  loading,
  originalContentIds,
  isEditMode,
}) => {
  const { t } = useTranslation();
  const [loadPageNumber, setLoadPageNumber] = useState(2);
  const [showLoadButton, setShowLoadButton] = useState(true);
  return (
    <List className={'scroll-bar-thumbnail'}>
      {contentList.map(productContent => {
        const selected = selectedContentIds && selectedContentIds.includes(productContent.id);
        const disabledToggleSelect =
          !!isEditMode && originalContentIds?.includes(productContent.id);
        return (
          <ListItem key={productContent.id} disabled={!!disabledToggleSelect}>
            <Checkbox
              disabled={disabledToggleSelect}
              checked={selected}
              onChange={() => toggleSelected(productContent.id)}
            />
            <ProductInfo productContent={productContent} width={96} aspectRatio={4 / 3} />
          </ListItem>
        );
      })}
      {loading ? (
        <LoadingFrame>
          <LoadingIndicator />
        </LoadingFrame>
      ) : null}
      {!loading && showLoadButton && contentList.length % defaultLoadingContentNumber === 0 ? (
        <Button
          type="outline"
          size="md"
          style={{ margin: '2rem auto' }}
          onClick={async () => {
            setShowLoadButton(false);
            const apiResult = await loadMoreContents(loadPageNumber);
            if ((apiResult?.perPage ?? 0) * loadPageNumber < (apiResult?.total ?? 0)) {
              setShowLoadButton(true);
            }
            setLoadPageNumber(loadPageNumber + 1);
          }}
        >
          {t('admin.playlist.createPlaylistDrawer.loadMoreContents')}
        </Button>
      ) : null}
    </List>
  );
};

export default ProductContentList;

const List = styled('div')({
  height: '100%',
  overflowY: 'auto',
  marginBottom: 24,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const ListItem = styled('div')<{ disabled: boolean }>(props => ({
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
  ...(props.disabled && {
    cursor: 'not-allowed',
    [`${ProductInfo}`]: {
      opacity: 0.5,
    },
  }),
}));

const ProductInfo = styled(_ProductInfo)({});

const Checkbox = styled(_Checkbox)({
  padding: 0,
  marginRight: 12,
});

const LoadingFrame = styled('div')({
  height: 'initial',
});
