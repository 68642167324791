import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import ContentItem from './ContentItem';
import { ContentViewLog, SharingLinkResult } from 'types/HearingLink';
import ClientInfo from './ClientInfo';

type Props = {
  result: SharingLinkResult;
  hideFeedbackSection: boolean;
  viewingLogs: ContentViewLog[];
};

const ProductContentResultItem: FC<Props> = ({ result, hideFeedbackSection, viewingLogs }) => {
  const { t } = useTranslation();

  return (
    <div>
      {result.form && <ClientInfo sharingLinkForm={result.form} />}
      <ProductContentList>
        <Title>{t('productContent.title')}</Title>
        {viewingLogs.length > 0 &&
          viewingLogs.map(content => {
            return (
              <ProductContentItem
                thumbnail={content.thumbnail_url || ''}
                title={content.title || ''}
                contentId={content.content_id}
                key={content.content_id}
                contentViewLog={content.logs}
                contentType={content.content_type}
              />
            );
          })}
      </ProductContentList>
      <Divider />
      {!hideFeedbackSection && (
        <Feedback>
          <Title>{t('sharingLink.clientCommentTitle').toUpperCase()}</Title>
          <FeebackContent>{result.sharing_link_result?.memo}</FeebackContent>
        </Feedback>
      )}
    </div>
  );
};

export default ProductContentResultItem;

const ProductContentList = styled('div')({});

const Title = styled('p')({
  fontSize: '1rem',
  color: palette.darkMedium2,
  marginBottom: 16,
  textTransform: 'uppercase',
});

const ProductContentItem = styled(ContentItem)({
  marginBottom: 20,
});

const Divider = styled('div')({
  margin: '20px 0',
  width: '100%',
  borderBottom: `1px solid ${palette.grayPrimary}`,
});

const Feedback = styled('div')({
  fontSize: 14,
});

const FeebackContent = styled('p')({
  wordBreak: 'break-all',
});
