import { useEffect, useState } from 'react';
import { useStore } from 'hooks';
import { useCurrentOrganization } from 'organisms/useCurrentOrganization';
import { LinkContentType, CreateHearingLinkData } from 'types/HearingLink';
import { trimSpace } from 'utility/helpers';

const defaultCreateHearingLinkData: CreateHearingLinkData = {
  administrative_title: '',
  administrative_memo: '',
  client_id: '',
  client_name: null,
  hearing_set_id: 0, //providing that there is no content/playlist/hearing_set with id = 0
  content_type: 'product_contents',
  product_content_id: 0,
  content_playlist_id: 0,
};

export type LinkContentData = {
  hearing_set_id?: number | null;
  content_type: LinkContentType;
  product_content_id?: number;
  content_playlist_id?: number;
};

export const useCreateHearingLink = (linkContentData: LinkContentData) => {
  const {
    hearingLinkStore: { getClientList, resetClientList, handleCreateNewHearingLink },
    appStore: { getOrganizationTags, displayableMenus, serviceEnabled },
  } = useStore();
  const currentOrganization = useCurrentOrganization();
  const [hearingLink, setHearingLink] = useState<CreateHearingLinkData>({
    ...defaultCreateHearingLinkData,
    ...linkContentData,
  });
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [openAccountForm, setOpenAccountForm] = useState(false);

  const disableLinkFunctions = !displayableMenus['sharing_link'] || !serviceEnabled;

  useEffect(() => {
    getClientList();
    return () => {
      resetClientList();
    };
  }, []);

  useEffect(() => {
    setHearingLink({
      ...hearingLink,
      ...linkContentData,
    });
  }, [linkContentData]);

  useEffect(() => {
    !!currentOrganization?.id && getOrganizationTags(currentOrganization.id);
  }, [currentOrganization?.id]);

  const handleChangeHearingLink = (updateData: Partial<CreateHearingLinkData>) => {
    setHearingLink({
      ...hearingLink,
      ...updateData,
    });
  };

  const resetHearingLink = () => {
    setHearingLink({ ...defaultCreateHearingLinkData });
  };

  const toggleAccountForm = () => setOpenAccountForm(!openAccountForm);

  const onChangeAccount = (value: string, isNew: boolean) => {
    if (value) {
      handleChangeHearingLink({
        client_id: isNew ? undefined : value,
        client_name: isNew ? value : undefined,
      });
    } else {
      handleChangeHearingLink({
        client_id: undefined,
        client_name: undefined,
      });
    }
  };

  const toggleCancelModal = () => {
    setCancelModalOpen(!isCancelModalOpen);
  };

  const handleCancelCreateLink = () => {
    resetHearingLink();
    setOpenAccountForm(false);
    toggleCancelModal();
  };

  const handleCreateHearingLink = () => {
    handleCreateNewHearingLink({
      content_type: hearingLink.content_type,
      administrative_title: trimSpace(hearingLink.administrative_title || ''),
      administrative_memo: trimSpace(hearingLink.administrative_memo || ''),
      hearing_set_id: hearingLink.hearing_set_id || null,
      ...(hearingLink.client_id && { client_id: hearingLink.client_id }),
      ...(hearingLink.client_name && { client_name: trimSpace(hearingLink.client_name) }),
      ...(hearingLink.product_content_id && { product_content_id: hearingLink.product_content_id }),
      ...(hearingLink.content_playlist_id && {
        content_playlist_id: hearingLink.content_playlist_id,
      }),
    });
  };

  return {
    currentOrganization,
    toggleCancelModal,
    isCancelModalOpen,
    handleCancelCreateLink,
    openAccountForm,
    onChangeAccount,
    toggleAccountForm,
    hearingLink,
    handleChangeHearingLink,
    handleCreateHearingLink,
    disableLinkFunctions,
  };
};

export const useCreateHearingLinkWithContentSelector = () => {
  const {
    hearingLinkStore: { getClientList, resetContentList, handleCreateNewHearingLink },
    appStore: { getOrganizationTags },
  } = useStore();
  const currentOrganization = useCurrentOrganization();
  const [hearingLink, setHearingLink] = useState<CreateHearingLinkData>({
    ...defaultCreateHearingLinkData,
  });
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [openAccountForm, setOpenAccountForm] = useState(false);
  //Select File Drawer State:
  const [isFileDrawerOpen, setFileDrawerOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<number>(0);
  //Select Questionnaire Drawer State:
  const [isQuestionnaireDrawerOpen, setQuestionnaireDrawerOpen] = useState(false);
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<number>(0);
  //Select Playlist Drawer State:
  const [isPlaylistDrawerOpen, setPlaylistDrawerOpen] = useState(false);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<number>(0);

  const disableCreateLink =
    !hearingLink.hearing_set_id &&
    !hearingLink.product_content_id &&
    !hearingLink.content_playlist_id;

  useEffect(() => {
    getClientList();
    return () => {
      resetContentList();
    };
  }, []);

  useEffect(() => {
    !!currentOrganization?.id && getOrganizationTags(currentOrganization.id);
  }, [currentOrganization?.id]);

  const toggleSelectFileDrawer = () => setFileDrawerOpen(!isFileDrawerOpen);
  const toggleSelectQuestionnaireDrawer = () =>
    setQuestionnaireDrawerOpen(!isQuestionnaireDrawerOpen);
  const toggleSelectPlaylistDrawer = () => setPlaylistDrawerOpen(!isPlaylistDrawerOpen);

  const handleChangeHearingLink = (updateData: Partial<CreateHearingLinkData>) => {
    setHearingLink({
      ...hearingLink,
      ...updateData,
    });
  };
  const resetHearingLink = () => {
    setHearingLink({ ...defaultCreateHearingLinkData });
  };
  const toggleAccountForm = () => setOpenAccountForm(!openAccountForm);
  const onChangeAccount = (value: string, isNew: boolean) => {
    if (value) {
      handleChangeHearingLink({
        client_id: isNew ? undefined : value,
        client_name: isNew ? value : undefined,
      });
    } else {
      handleChangeHearingLink({
        client_id: undefined,
        client_name: undefined,
      });
    }
  };

  const handleSelectPlaylist = (id: number) => {
    setSelectedPlaylistId(id);
  };

  const handleSelectFile = (id: number) => {
    setSelectedFileId(id);
  };

  const handleSelectQuestionnaire = (id: number) => {
    setSelectedQuestionnaireId(id);
  };

  const clearSelectState = () => {
    setSelectedFileId(0);
    setSelectedPlaylistId(0);
    setSelectedQuestionnaireId(0);
  };

  const toggleCancelModal = () => {
    setCancelModalOpen(!isCancelModalOpen);
  };

  const handleCancelCreateLink = () => {
    resetHearingLink();
    clearSelectState();
    setOpenAccountForm(false);
    toggleCancelModal();
  };
  const handleCreateHearingLink = () => {
    handleCreateNewHearingLink({
      content_type: hearingLink.content_type,
      administrative_title: trimSpace(hearingLink.administrative_title || ''),
      administrative_memo: trimSpace(hearingLink.administrative_memo || ''),
      hearing_set_id: hearingLink.hearing_set_id || null,
      ...(hearingLink.client_id && { client_id: hearingLink.client_id }),
      ...(hearingLink.client_name && { client_name: trimSpace(hearingLink.client_name) }),
      ...(hearingLink.product_content_id && { product_content_id: hearingLink.product_content_id }),
      ...(hearingLink.content_playlist_id && {
        content_playlist_id: hearingLink.content_playlist_id,
      }),
    });
  };

  const handleChangeLinkType = (contentType: LinkContentType) => {
    handleChangeHearingLink({
      content_type: contentType,
      product_content_id: 0,
      content_playlist_id: 0,
      hearing_set_id: 0,
    });
  };

  return {
    currentOrganization,
    disableCreateLink,
    toggleCancelModal,
    isCancelModalOpen,
    handleCancelCreateLink,
    openAccountForm,
    onChangeAccount,
    toggleAccountForm,
    hearingLink,
    toggleSelectFileDrawer,
    toggleSelectPlaylistDrawer,
    toggleSelectQuestionnaireDrawer,
    isFileDrawerOpen,
    isPlaylistDrawerOpen,
    isQuestionnaireDrawerOpen,
    handleSelectFile,
    handleSelectPlaylist,
    handleSelectQuestionnaire,
    selectedFileId,
    selectedPlaylistId,
    selectedQuestionnaireId,
    clearSelectState,
    handleChangeHearingLink,
    handleCreateHearingLink,
    handleChangeLinkType,
  };
};
