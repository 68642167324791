export const defaultUser = {
  id: '',
  username: '',
  email: '',
  profile_image_url: '',
  allow_public_profile: false,
  created_at: '',
  deleted_at: null,
  is_admin: false,
  valid_personal_information: false,
  organizations: [],
};

export const defaultFlashMessage = {
  content: '',
  status: 'success' as const,
};

export const defaultDisplayableMenus = {
  sales_meeting: false,
  sharing_link: false,
  embedding: false,
};
