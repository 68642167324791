import { QuestionViewType } from 'entity/Question';
import { QuestionSetViewType } from 'entity/QuestionSet';
import {
  ExternalVideoURLMeta,
  NoContentMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
  ContentType,
  ExistingContentMeta,
  Chapter,
} from 'types/App';
import { ListMeta } from 'types/common';
import { HearingItem } from 'types/HearingSet';
import { DefaultLink, ProductItem } from 'types/Product';

export const defaultContentType: ContentType = 'existing_contents';

export const defaultProductContentType: ContentType = 'videos_images_pdfs';

export const defaultChapter: Chapter = {
  name: '',
  position: 0,
};

export const defaultVideoMeta: UploadVideoMeta = {
  type: 'videos',
  url: '',
  file: '',
  fileThumbnail: '',
  fileThumbnailConst: '',
  videoSrcConvert: '',
  thumbnailSrcConvert: '',
  firstFrameVideo: '',
  filename: '',
  error: '',
  service: null,
  chapters: null,
};

export const defaultImageMeta: UploadImageMeta = {
  type: 'images',
  file: '',
  filename: '',
  src: '',
  error: '',
  service: null,
};

export const defaultPDFMeta: UploadPDFMeta = {
  type: 'pdfs',
  file: '',
  thumbnailFile: '',
  filename: '',
  thumbnailSrc: '',
  error: '',
  service: null,
};

export const defaultExtrenalVideoURLMeta: ExternalVideoURLMeta = {
  type: 'links',
  platform: 'vimeo',
  url: '',
  thumbnailFile: undefined,
  thumbnailSrc: '',
  thumbnailURL: '',
  error: '',
};

export const defaultNoContentMeta: NoContentMeta = {
  type: 'none_data',
};

export const defaultListMeta: ListMeta = {
  sort: '',
  order: '',
  page: 1,
  total: undefined,
};

export const defaultExistingContentMeta: ExistingContentMeta = {
  type: 'existing_contents',
  creativeId: '',
  creative_type: '',
};

export const defaultProductDetail: ProductItem = {
  id: -1,
  content_type: 'videos',
  title: '',
  created_at: '',
  updated_at: '',
  organization_id: 0,
  description: '',
  public_description: '',
  status: 'published',
  upload_status: '',
  kaizen_files_thumbnail_url: '',
  kaizen_files_bucket_id: '',
  kaizen_files_id: '',
  kaizen_files_url: '',
  kaizen_files_original_url: '',
  service: null,
  content_id: 0,
  service_resource_id_for_logger: '',
  chapters: null,
};

export const defaultHearing: HearingItem = {
  id: 0,
  status: 'draft',
  title: '',
  question_sets: [
    {
      id: 0,
      content: null,
      service_resource_id_for_logger: '',
      questions: [
        {
          id: 0,
          body: '',
          can_delete: true,
          choices: [{ id: 0, question_id: 0, content: '', feedback: '' }],
          is_multiple_choice: true,
          has_other: true,
          question_set_id: 0,
        },
      ],
    },
  ],
};

export const defaultQuestion: QuestionViewType = {
  can_delete: true,
  has_other: true,
  is_multiple_choice: true,
  body: '',
  choices: [
    {
      content: '',
      can_delete: true,
      feedback: '',
    },
  ],
};

export const defaultQuestionSet: QuestionSetViewType = {
  id: 0,
  content: { content_type: 'none_data' },
  questions: [defaultQuestion],
};

export const defaultDefaultLink: DefaultLink = {
  id: 0,
  client_id: null,
  needs_feedback: true,
  cta: [],
  created_at: '',
  updated_at: '',
  status: '',
  hearing_set_id: null,
  product_contents: [],
  administrative_memo: '',
  administrative_title: '',
  custom_fields: {
    company_name: false,
    company_pic_name: false,
    email: false,
    phone_number: false,
  },
  content_type: 'product_contents',
};
