import { ProductItem } from 'types/Product';

export const defaultHearingMemo = {
  company_name: undefined,
  company_pic_name: '',
  memo: '',
};

export const defaultProductDetail: ProductItem = {
  id: -1,
  content_type: 'videos',
  title: '',
  description: '',
  public_description: '',
  kaizen_files_url: '',
  kaizen_files_bucket_id: '',
  kaizen_files_thumbnail_url: '',
  kaizen_files_id: '',
  kaizen_files_original_url: '',
  status: '',
  organization_id: 0,
  created_at: '',
  updated_at: '',
  service: null,
  content_id: -1,
  service_resource_id_for_logger: '',
};
