import { Languages } from './models';

const en: Languages = {
  common: {
    here: 'here',
    title: 'Title',
    description: 'Description',
    publicDescription: 'Public Description',
    requiredInput: 'Required',
    createdDate: 'Created Date',
    lastUpdated: 'Last Updated',
    organization: 'Organization',
    accountMenu: 'Account Settings',
    notFound: '404 Not Found',
    sort: 'Sort',
    select: 'Select',
    select2: 'Select',
    add: 'Add',
    createNew: 'Create',
    save: 'Save',
    save2: 'Save',
    saving: 'Saving',
    uploading: 'Uploading',
    edit: 'Edit',
    cancel: 'Cancel',
    ok: 'OK',
    sortLatest: 'Latest',
    sortOldest: 'Oldest',
    sortASC: 'A - Z',
    sortDESC: 'Z - A',
    createAppointment: 'Create Appointment',
    startHearing: 'Start Hearing',
    infomation: 'Information',
    companyName: 'Company Name',
    companyName2: 'Company Name',
    name: 'Name',
    mailAddress: 'Mail Address',
    phoneNumber: 'Phone Number',
    externalClientId: 'Client ID',
    customerName: 'Customer Name',
    timeTitle: 'Time',
    byTitle: 'By',
    customerTitle: 'Customer',
    memoTitle: 'Memo',
    loginTitle: 'Login With Kaizen ID',
    logoutTitle: 'Logout',
    openAccountConsole: 'Open Account Console',
    leaveConfirmMessage: 'All data will be cleared, are you sure?',
    yesText: 'Yes',
    noText: 'No',
    notSpecified: 'Not Specified',
    confirmCancel: 'Are you sure you want to cancel?',
    confirmLeave: 'Are you sure you want to leave?',
    warningUndone: 'This cannot be undone!',
    emptyMessages: 'Your collection list is empty!',
    emptySearchResult: 'No item matches your search!',
    emptyData: 'Your data is empty.',
    accountSetting: 'Account Setting',
    profile: 'Your Profile',
    link: 'Link',
    send: 'Send',
    videoNotavailable: 'This video will be available for viewing shortly !',
    videoUploadFailed: 'Failed to upload video. Please contact your admin.',
    comment: 'comment',
    csvDownload: 'Download CSV',
    csvDownloadFailed: 'Failed to download CSV',
    csvDownloadOption: {
      utf8: 'UTF-8',
      shiftjis: 'SHIFT-JIS',
    },
    reload: 'Reload',
    actionTarget: {
      hearings: 'questionnaires',
      products: 'content',
      links: 'shareable link',
      playlists: 'playlist',
    },
    noOrganizationMessage:
      "Can not access since you don't belong to an organization. Please ask your admin for invitation.",
    tooltipText: {
      moveUp: 'Move Up',
      moveDown: 'Move Down',
      delete: 'Delete',
      changeFile: 'Change File',
      downloadVideo: 'Download',
      cantChangeFile: "Can't Change file which is created by Creater",
      edit: 'Edit',
    },
    changeOrder: 'Change Order',
    filter: 'File Type',
    contentLabelText: {
      videos: 'VIDEO',
      pdfs: 'PDF',
      images: 'IMAGE',
      unknown: 'Unknown',
    },
    preview: 'Preview',
    goBack: 'Go Back',
    notFoundResult: 'Cannot get hearing result',
    close: 'Close',
    viewAll: 'View All',
    collapse: 'Collapse',
    filters: 'Filters',
    format: 'Format',
    requiredInputError: '{{ target }} is required.',
    inDeveloping: '* This feature is under development.',
    setting: 'Setting',
    report: 'Report',
    search: 'Search',
    search2: 'Search',
    download: 'Download',
    downloading: 'Downloading',
    downloadFailed: 'Failed to download',
    downloaded: 'Downloaded',
    copy: 'Copy URL',
  },
  sidebar: {
    salesContent: 'Sales Content',
    productContent: 'Product Content',
    hearings: 'Questionnaire',
    salesLog: 'Sales Log',
    salesTitle: 'Sales',
    managementTitle: 'Management',
    hearingLink: 'Shareable Link',
    setting: 'Settings',
    embeddingLink: 'Embedding Link',
  },
  salesContent: {
    salesContentTitle: 'Sales Content',
    hearing: 'Hearing Set',
    hearingSetPlural: 'Hearing Sets',
  },
  hearingSet: {
    searchPlaceholder: 'Find a questionnaires, appointment...', // not in use
    hearingSetTitle: 'Hearing Set',
    questionnaireTitle: 'Questionnaires',
    hearingItem: {
      numOfVideo: '{{count}} Questions',
    },
  },
  quickHearing: {
    addAppointment: 'Add Appointment',
    viewResult: 'View Result',
    hubSpotResult: 'View HubSpot',
    notFoundAppointment: 'Appointment does not exist!',
  },
  hearingPlay: {
    multipleChoosable: '',
    previousQuestion: 'Previous Question',
    nextQuestion: 'Next Question',
    endHearing: 'End Hearing',
    endAppointment: 'End Appointment',
    end: 'End',
    cancelHearing: 'Cancel Hearing',
    otherAnswerPlaceHolder: 'Your comment',
    result: {
      hearingTitle: 'Hearing Result',
      questionnaireTitle: 'Access History',
      watchTitle: 'Watching Result',
      answerLabel: 'Answers',
      feedbackLabel: 'Feedback for answer',
      noteLabel: 'Note',
      addHearingSet: 'Add Hearing Set',
      addProduct: 'Add Product Contents',
      companyLabel: 'Company Name - Optional',
      customerNameLabel: 'Customer Name - Optional',
      createMemo: 'Create Memo',
      memoLabel: 'Memo',
      namePlaceholder: 'Enter name',
      memoPlaceholder: 'Enter memo',
      buttonSave: 'Save and Close',
      searchHearingPlaceholder: 'Find a hearing set',
      endHearing: 'End Hearing',
      otherAnswerLabel: 'Other',
      relatedVideos: 'Recommended content from your answers',
      registerAfterMeeting: 'Register after meeting',
      registerAfterMeetingNotice:
        'Please register after the meeting, because other company names will also appear in the search results.',
    },
    messages: {
      createQuickHearingSuccess: 'Create Quick Hearing Success!',
    },
    noneDataMessage: 'This question has no attached content.',
    backToResult: 'Back to Result',
    backToSalesContent: 'Back to all Contents',
    recording: 'Recording',
    alreadyAnswered: 'Answered',
    confirmLeaveHearing: 'Are you sure to leave? All answers will not be saved.',
    cancelRecording: 'Recording has been cancelled.',
  },
  productContent: {
    title: 'Product Content',
    startAppointment: 'Start Appointment',
    createDate: 'Date Created',
    otherContent: 'Other Contents',
    searchContentPlaceholder: 'Search product contents',
    showMoreContents: 'Show More Contents',
    showMoreQuestionnaires: 'Show More Questionnaires',
    filterType: {
      all: 'All Types',
      video: 'Video',
      other: 'Other',
    },
    allTags: 'All Tags',
  },
  createAppointment: {
    messages: {
      createAppointmentSuccess: 'Create Appointment Success!',
    },
    errors: {
      companyName: 'Company Name is required',
      clientId: 'Company Name is required',
    },
  },
  appointmentDetail: {
    title: 'Appointment Details',
    deleteSuccess: 'Appointment deleted',
    updateSuccess: 'Appointment updated',
  },
  sharingLink: {
    searchLinks: 'Search Shareable Link',
    screenTitle: 'Shareable Link',
    linkCount: 'Link Count',
    viewCount: 'View Count',
    addHearingLink: 'Create Link',
    disabledCreateLinkTooltip: 'Please published this {{type}} to create shareable link with it.',
    addLinkDrawerTitle: 'Create Link',
    accountLabel: 'Customer Company',
    titleAndAccountLabel: 'Title / Customer Company',
    noSpecifiedAccount: 'No specified customer company',
    createNewOption: 'Create new account',
    createdByLabel: 'Created By',
    clientCommentTitle: 'FEEDBACK COMMENT',
    clientCommentPlaceHolder:
      "e.g. I'd like to learn more about services and would like to have a meeting.",
    messages: {
      createHearingLinkFail: 'Failed to create shareable link!',
      createHearingLinkSuccess: 'Created shareable link successfully!',
      updateHearingLinkSuccess: 'Updated shareable link successfully!',
      updateHearingLinkFail: 'Failed to update shareable link!',
      notFoundHearingLink: 'Shareable link does not exist!',
    },
    copySuccess: 'Copied',
    copyFail: 'Failed to copy',
    copyDefault: 'Copy URL',
    noPublicHearing: 'Questionnaire is draft or archived. Please change its status to public',
    noPublicProductContent:
      'All contents are draft or archived. Please change their statuses to public',
    noPublicPlaylist: 'Playlist is draft or archived. Please change its status to public',
    urlParameters: 'URL PARAMS',
    selectAll: 'Select all',
    releaseAll: 'Release all',
    filter: {
      all: 'All',
      mine: 'My links',
    },
    previewTooltip:
      'Preview does not send viewing or response data. It can be used to check the settings.',
    viewSetting: {
      title: 'Settings For Viewing',
      beforeViewing: 'Before Viewing',
      afterViewing: 'After Viewing',
      showCustomForm: 'Show Custom Form',
      subShowFormLabel: 'Viewers must enter a identification form to view link.',
      noCustomFieldSelected: 'Please select fields for the form',
      showResult: 'Show Results Screen',
      subShowResultLabel: 'Show results screen, viewers can input their comments here.',
      ctaSetting: {
        defaultLabel: 'Inquiry',
        subSectionTitle: 'CTA',
        autoRedirect: 'Auto Redirect',
        noCTAButton: 'No CTA added',
        addCTAButton: 'Add New CTA',
        guideText: '(*This option can be selected if there is only one CTA)',
        invalidUrl: 'Invalid URL',
        requireUrl: 'Please enter an URL',
        addCTA: 'Add CTA',
        editCTA: 'Edit CTA',
        dislayText: 'Display Text',
        url: 'URL',
        displayTextPlaceHodler: 'E.g: Inquiry',
        urlPlaceHolder: 'Enter URL',
      },
    },
    addTitleAndContent: 'Add Title And Select Content',
    linkType: 'Link Type',
    noAdministrativeTitle: 'No Title',
    accessTimeLabel: 'Access time',
    switchLink: 'ON/OFF Link',
    administrationSetting: {
      drawerTitle: 'Edit Title And Memo',
      titleLabel: 'Title for managing',
      memoLabel: 'Memo',
      subMemoLabel: "Viewers can't view this field.",
      subTitleLabel: "Viewers can't view this field.",
      clientAccountLabel: 'Set Customer Company',
      subClientAccountLabel:
        'Use for identifying or grouping viewers by company. It cannot be changed after the link is created to prevent missending.',
    },
    selectType: {
      productContents: {
        label: 'Product Contents',
        tooltip:
          'Display one content per page. It is effective for getting the content to be viewed carefully.',
      },
      questionnaire: {
        label: 'Questionnaire',
        tooltip: 'You can view surveys by content to get viewer data about your business.',
      },
      playlist: {
        label: 'Playlist',
        tooltip:
          'Always display multiple content viewing buttons to facilitate switching between viewing content.',
      },
    },
    selectQuestionnaire: 'Select Questionnaire',
    selectFile: 'Select File',
    selectPlaylist: 'Select Playlist',
    linkContentType: {
      productContents: 'Product Contents',
      questionnaire: 'Questionnaire',
      playlist: 'Playlist',
    },
    resultModal: {
      openContentDetail: 'View Product Content Detail',
      openHearingDetail: 'View Questionnaire Detail ',
      questionHasNoAnswer: 'Question has not been answer yet.',
    },
    getViewingLogsError: 'Failed to fetch viewing logs.',
    noViewingLogs: 'No Viewing Logs',
    disableCreateLink: 'Contract process is needed for using this feature. Please contact ',
    startTimeSetting: {
      setStartTimeButton: 'Set Start Time And Copy',
      modalTitle: 'Set Start Time And Copy',
      copyButton: 'Copy',
      startTimeInputLabel: 'Starting position',
      invalidTimeError: 'Start time cannot be larger than video duration.',
    },
    selectFileModalTitle: 'Select File',
    selectPlaylistModalTitle: 'Select Playlist',
    selectQuestionnaireModalTitle: 'Select Questionnaire',
  },
  embeddingLink: {
    embeddingLink: 'Embedding Link',
    createEmbeddingLink: 'Create Embed Video',
    infoSetting: {
      title: 'Title and Description',
      infoInput: 'Edit Title and Description',
    },
    controlOption: {
      showPlaybarControl: 'Playbar Control',
      showVolumeControl: 'Volume Control',
      showCenterPlayButton: 'Center Play Button',
      enabledFullscreen: 'Full Screen',
      volumeControlTooltip: 'Volume Control must always be displayed when video is unmuted.',
    },
    endScreenType: {
      guide:
        'To enable types other than Auto Replay, turn on Center Play Button or Playbar Control.',
      thumbnail: 'Thumbnail',
      loop: 'Auto Replay',
    },
    playbackSetting: {
      title: 'Playback Settings',
      control: 'Control',
      endScreen: 'End Screen',
      startTimeSettings: 'Start Time Settings',
    },
    startTimeSettings: {
      autoplay: 'Autoplay',
      muted: 'Muted',
      mutedTooltip:
        "Most mobile browsers do not allow to play video with sound on, so if 'Autoplay' option is checked, video will be muted on mobile. \nAlso, even on PC, if there is no user interaction, video might not be played automatically when volume is turned on. Therefore, it is recommended to mute when autoplay is on.",
      autoplayTooltip:
        "To turn off autoplay, check the 'Center Play Button' or 'Playbar Control' so that users can play the video.",
    },
    embedCode: 'Embed Code',
    embedCodeCloud: 'Web Optimization Cloud',
    embedCodeCloudDescription1: 'Embed with Experience Script',
    embedCodeCloudDescription2: 'Embed with GUI Editor',
    embedCodeHTML: 'HTML',
    embedCodeHTMLDescription1: 'This belongs to <head/>',
    embedCodeHTMLDescription2: 'This belongs to <body/>',
    experienceScriptLink: 'Experience Script ?',
    showEmbedCode: 'Show Embed Code',
    copyCode: 'Copy Embed Code',
    copySuccess: 'Code copied sucessfully!',
    copyFail: 'Failed to copy code',
    embedVideo: 'Embed Video',
    selectEmbedVideoGuide: 'Select Video to Embed',
    selectVideo: 'Select Video',
    edit: 'Edit',
    contentType: {
      embeddingVideo: 'Simple Video',
      interactiveVideo: 'Interactive Video',
    },
    noVideoSelected: 'No Video Selected',
    title: 'Embedding Link',
    addEmbeddingLink: 'Create Embed Code',
    linkListTitle: 'Embed Code',
    createNewFrame: 'Create New Frame',
    messages: {
      createSuccess: 'Created Embed Item successfully!',
      createFail: 'Failed to create Embed Item',
      updateSuccess: 'Updated Embed Item successfully!',
      updateFail: 'Failed to update Embed Item',
      linkNotFound: 'Item not found',
      getInteractiveHearingError: 'Failed to fetch Interactive Video list',
      getViewRateError: 'Failed to fetch Video View Rate',
    },
    selectType: {
      title: 'Format',
      simpleVideo: {
        label: 'Simple Video',
        tooltip: 'Embed a single video. Effective for appealing in LP and Top Page.',
      },
      questionnaireVideo: {
        label: 'Questionnaire Video',
        tooltip:
          'Answer the questionnaire while watching the video. Effective for confirming needs and surveying satisfaction.',
      },
      interactiveVideo: {
        label: 'Interactive Video',
        tooltip:
          'Content of the video will change according to selected choices. Provide an interactive experience.',
      },
    },
    selectEmbedVideo: {
      title: 'Select Video to Embed',
      guide: 'Only published Product Content will be displayed',
    },
    interactiveVideoSection: {
      selectVideo: 'Select Interactive Video',
      changeVideo: 'Change Interactive Video',
      drawerTitle: 'Select Interactive Video',
      guide:
        'Questionnaire cannot be changed once the link is created to ensure accuracy of data collection.',
    },
    reportTab: {
      noViewRate: {
        mainLabel: 'No view result',
        subLabel: 'It may take time until the result is updated',
      },
      noVideo: 'No video is selected to embed yet',
      answerCount: 'Answers',
      other: 'Other',
    },
  },

  modalhearing: {
    questionnaire: 'Questionnaire',
    addQuestionnaire: 'Add Questionnaire',
    searchQuestionnaire: 'Search questionnaires',
  },
  usingServiceInquiry: {
    movieUrl:
      'https://files.kaizenplatform.net/encoded/1494/0/4c0cc0faf9579a4b6338c6256f410f12c9b826667de0cce411f10f07a3b25dc1/abda7aa9-c4f1-4a79-ba98-d7b260878cd5_orig.m3u8?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImM4NGZjOWMyNzFmNGYwODY1OTJkZTE5YmQ1MWViZGM2OGQ0YWNhYzM4MGY4ODdjMmRkMDlkOGYyMjkyM2ViZGIxZGViYmFlYzYxZTQ4ZmEwMGJjYzBjZTRhMGZjN2I4ODgzYmExOTJjOWU1NjU4YWFiNTFjZDlmZTNmY2ZiOGU2YTlhYWYzM2RlYmNhMWE2OGM4N2I4MDRiYjc4NmQxYjVkNzkwZjYzNjVmMGRhM2Y5YTc3Nzg3YjVlNjk2NDBkYTdkZmI2ZmIwZTcwM2I0YWEzMDI2NjVkYzFlMmU0ZjYyYjg4M2I5OTdiNTY0MWVkZjIxNmQ4MDlkMmZjOWE4ODMyODE5MWUyZWUyMDJiNDJhZDA1YTdiNzA5MDc1OWRiZDcwYjAwYmJhODEwNGE0NTJjMzEyZWYzNjc3Zjg3NmY4YTU3NmZiZTZjMjliODQ3ZWZkMmVlNDc0OTRhYjgzNDg4YzRkMTA2NzgxODkyNDFmODI4ZjZjOGFlOTk0MGU1MmEzYTExYjNjMTBmYTAxIn0.TFQic_cEwlYshgXGGczh4OsJf6kuiDTWT6A8RFfGv60',
    text: 'KAIZEN Sales accelerates DX in the sales and marketing domain with video and data.',
    button: 'Contuct us',
    href: 'https://kaizenplatform.com/lp/sales/',
    notice: 'Contact us to use the feature.',
  },
  salesLog: {
    title: 'Sales Log',
    tabName: {
      quickHearing: 'Quick Hearing',
      appointment: 'Appointment',
    },
  },
  admin: {
    common: {
      filename: 'Filename',
      uploadProductContent: 'Upload Content',
      uploadThumbnail: 'Upload Thumbnail',
      uploadFileButtonLabel: 'Upload New Content',
      uploadThumbnailButtonLabel: 'Upload New Thumbnail',
      dropzoneGuide: 'Click to select or upload file here',
      content: 'Content',
      draft: 'Draft',
      publish: 'Publish',
      archive: 'Archive',
      setting: 'Settings',
      restore: 'Restore',
      dropzoneMainGuide: 'Upload {{ type }} file',
      browse: 'Browse',
      thumbnail: 'Thumbnail',
      title: 'Title',
      checkbox: 'Checkbox',
      radio: 'Radio',
      delete: 'Delete',
      productTitlePlaceholder: 'Enter title of product',
      description: 'Description',
      productDescriptionPlaceholder: 'Write something about this product',
      confirmArchive: 'Yes, Archive',
      confirmDelete: 'Yes, Delete',
      messageConfirmArchive: 'Are you sure to archive this {{ item }} ?',
      messageConfirmDelete: 'Are you sure you want to delete ?',
      cancelArchive: 'No, Cancel',
      messages: {
        archiveSuccess: 'Archive Success',
        restoreSuccess: 'Restore Success',
        deleteSuccess: 'Delete Success',
        fileExtensionError: 'Only Accept Format: {{ validExtension}}',
        cannotViewPlaylistDetail: 'Archived playlist cannot be opened.',
        updateStatusFailed: 'Item cannot be draft as it is in use.',
      },
      uploadingProgress: 'File uploading...',
      uploadFailed: 'Upload failed: ',
      notLoadThumbnail:
        '{{filename}} cannot load video thumbnail, please upload an image to set video thumbnail',
      exceedMaxSize: 'File is too large. Please choose a file within {{ sizeLimit }}MB.',
      loadingThumbnail: 'Loading thumbnail...',
      notViewDetail: "File can not be opened because it's in the Trash",
      notFileVideo: '{{filename}}: The file video format is not valid',
      notFileImage: 'The file thumbnail format is not valid',
      notSupportedCodec:
        'Video uses codec that is not supported by the browser. Please upload video with codec H.264',
      isDraftOrArchived: 'Status has been changed to draft',
      itemInUse: 'Item in use',
      dropzoneTarget: {
        videos: 'videos',
        images: 'images',
        pdfs: 'pdfs',
        none: '',
        videos_images_pdfs: 'video, image, or pdf',
      },
      dropzoneOptionDivider: 'or',
      changeToPublic: 'Make Public',
      changeToDraft: 'Make Draft',
    },
    productContent: {
      titleHeader: 'Product Content Management',
      upload: 'Upload',
      emptyProduct: 'Product content list is empty.',
      titleBlank: 'Title is required',
      descriptionBlank: 'Description is required',
      messages: {
        changeStatusSuccess: 'Change status success!',
        createSuccess: 'Created product content successfully!',
        updateProductSuccess: 'Updated product content successfully!',
        updateProductFail: 'Failed to update product content.',
        notFoundProduct: 'Product content does not exist!',
        thumbnailResolutionError:
          'Thumbnail file cannot be larger than {{maxWidth}}px wide or {{maxHeight}}px tall.',
        confirmChangeFile1: 'The Questionnaires which use this Content will also  be Changed',
        confirmChangeFile2: '',
        getHearingSetsError: 'Get Hearing Sets failed',
        cannotChangeContentType: 'Content type cannot be changed after being created.',
        chapterTimeExceedMaxSize: 'Chapter time must be smaller than video duration.',
        createDefaultLinkSuccess: 'Created default link successfully!',
        createDefaultLinkFail: 'Failed to create default link!',
      },
      contentInUse:
        'This content is being used in a shareable link. Are you sure to change it to draft?',
      emptyState: 'Upload your video or order video production from the creators.',
      orderVideo: 'Order video',
      orderVideoHref: 'https://cloud.kaizenplatform.net/ad/latest-insights',
      changeFileComfirm: 'Change File',
      changeFile: 'Change File',
      hearingSetTitleHearder: 'Questionnaires which use this Product Content',
      chapterSection: {
        title: 'Chapter',
        noChapter: 'No chapter added.',
        addChapter: 'Add Chapter',
        maximumNumberOfChapters: 'You can add 20 chapters at maximum.',
        blankInputError: 'Chapter name are required.',
      },
    },
    hearingSet: {
      titleHeader: 'Questionnaires Management',
      addHearing: 'Add Questionnaire',
      placeholderTitleHearing: 'Enter title of questionnaires',
      addQuestions: 'Add Question',
      noTitle: 'No Specified Title',
      messages: {
        createHearingSuccess: 'Create new questionnaire success!',
        createHearingError: 'Create questionnaire failed!',
        updateHearingSuccess: 'Update questionnaire success!',
        updateHearingFail: 'Update questionnaires failed!',
        titleBlank: "Questionnaire's title is required",
        questionBlank: "Questionnaire's question is required",
        noResults: 'No search results',
        notFoundHearing: 'Questionnaire does not exist!',
        statusValidationError: "Questionnaire without questions can't be published",
      },
      hearingSetInUse:
        'This Questionnaire is being used in a shareable link. Are you sure to change it to draft?',
      questionIndex: 'No.{{ index }}',
      saveQuestionOrder: 'Save Question Order',
      editQuestionOrder: 'Sort Questions',
      editQuestionOrderHint: 'You can drag & drop each question to rearrange the order',
      instanceType: {
        contentAndQuestionnaire: {
          label: 'Content + Questionnaire',
          tooltip: 'Content is displayed with questions and answers below.',
        },
        interactiveVideo: {
          label: 'Interactive Video',
          tooltip:
            "Answer choices over video. Next video's content depends on selected choice from previous one, providing an interactive experience.",
        },
      },
      disabledPreviewTooltip: 'Please add at least one question to preview this questionnaire.',
    },
    questions: {
      addQuestion: 'Add Question',
      deleteQuestion: 'Delete Question',
      editQuestion: 'Edit Questions',
      submittingAddQuestion: 'Submitting',
      savingUpdateQuestion: 'Saving',
      question: 'Question',
      content: 'Your Content',
      placeholderQuestion: 'Enter Question',
      placeholderFeedback: 'Feedback for answer',
      placeholderAnswer: 'Answer {{index}}',
      addAnswer: 'Add answer',
      countAnswers: '{{count}} Answers',
      createSuccess: 'Create new question success!',
      contentFormLabel: {
        videos: 'Upload Video',
        links: 'Video Link',
        images: 'Image',
        pdfs: 'PDF',
        existing_contents: 'Existing Contents',
        none_data: 'None',
        videos_images_pdfs: 'Upload File',
      },
      embedURL: 'EMBED URL',
      embedVideoLink: 'Embed Video Link',
      embedModalLabel: 'Paste a URL (Youtube, Vimeo) below to embed video',
      videoURL: 'Video URL',
      changeLink: 'Change Link',
      addOther: 'Add option "Other"',
      errors: {
        invalidVideoURL: 'Please input valid Vimeo/YouTube video URL',
        notFound: "Couldn't find video from inputted URL",
      },
      messages: {
        questionBlank: "Question's title is required",
        answerBlank: "Answer's content is required",
        createQuestionSuccess: 'Create new question success!',
        updateQuestionSuccess: 'Update question success!',
        createQuestionError: 'Create question failed!',
        updatedQuestionError: 'Update question failed!',
        deleteQuestionSuccess: 'Deleted question successfully',
        deleteQuestionError: 'Failed to delete question',
        updateContentCaution1: 'The uploaded file will only be used for this question.',
        updateContentCaution2:
          'If you want to use it for other questions or product contents, please upload it from the Product Content.',
      },
      maximumNumberOfAnswer: 'You can add 10 answers at maximum',
      maximumNumberOfQuestion: 'You can add 10 questions at maximum',
    },
    setting: {
      tabs: {
        personal: 'User settings',
        organization: 'Organization settings',
      },
      changeLogo: {
        title: 'Replace Logo',
        button: 'Replace',
        currentLogo: 'Current Logo',
        newLogo: 'New Logo',
        uploadLogoAcceptedFormats: 'Acceptable formats: jpeg, png, gif',
        uploadLogoMaxSize: 'Max file size is 1MB',
        messages: {
          changeLogoSuccess: 'Logo updated successfully',
          changeLogoError: 'Failed to update logo',
        },
      },
      slack: {
        title: 'Slack Link',
        link: 'Link',
        linked: 'Linked',
        unlink: 'Unlink',
        drawer: {
          title: 'Slack 連携設定',
        },
        messages: {
          assignSuccess: 'Slack assigned successfully',
          success: 'Slack linked successfully',
          deleteSuccess: 'Slack unlinked successfully',
          slackIntegrationError: 'Failed to slack integration',
        },
      },
      hubSpot: {
        title: 'HubSpot Link',
        link: 'Link',
        linked: 'Linked',
        unlink: 'Unlink',
      },
    },
    previewHearing: {
      endPreview: 'End Preview',
      noQuestion: 'No question found under the hearing set',
    },
    content: {
      listPageTitle: 'Content List',
      tabName: {
        files: 'File',
        playlists: 'Playlist',
        questionnaires: 'Questionnaire',
      },
    },
    playlist: {
      searchPlaceHolder: 'Search Playlist',
      createPlaylistDrawer: {
        title: 'Create Playlist',
        selectContents: 'Add Contents',
        guideText:
          "Product contents cannot be replaced once the link is created to ensure accuracy of data collection. (You can still change contents' order)",
        playlistTitleLabel: 'Title for managing',
        playlistTitleSubLabel: "Viewers can't view this field.",
        playlistMemoLabel: 'Memo',
        playlistMemoSubLabel: "Viewers can't view this field.",
        loadMoreContents: 'Load more contents',
      },
      messages: {
        createPlaylistSuccess: 'Created Playlist successfully!',
        createPlaylistFailed: 'Failed to create Playlist',
        getPlaylistListFailed: 'Cannot fetch playlists',
        getPlaylistFailed: 'Cannot fetch playlist item',
        updatePlaylistSuccess: 'Update Playlist successfully',
        updatePlaylistFailed: 'Failed to update Playlist',
      },
      contentCount: '{{count}} files',
      playlistSetting: {
        changeDisplayText: 'Change Displayed Text',
        displayedText: 'Displayed Text',
        drawerTitle: 'Edit Contents',
        instruction:
          'You can change the text displayed on the playlist buttons. \n*Title of the used content is automatically registered. Changing display text does not affect the title of your content.',
        displayedTextBlank: 'Display text is required.',
      },
    },
  },
  creativeSearch: {
    contentType: {
      video: 'video',
      image: 'image',
      pdf: 'PDF',
      others: 'others',
    },
    fileType: 'Content Type',
    select: 'Select',
    selectAnotherContent: 'Another Content',
  },
};

export default en;
