import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'views/components/compound';
import ProductContentResult from './ProductContentResult';
import HearingResult from './HearingResult';
import { ProductItem } from 'types/Product';
import { HearingItem } from 'types/HearingSet';
import { ViewingLog, SharingLinkResult } from 'types/HearingLink';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  isModal: boolean;
  toggleModal: () => void;
  linkResults: SharingLinkResult[];
  title: string;
  activeSessionVisitId?: number;
  productContents?: ProductItem[];
  submitSaveNote: (sessionId: string) => void;
  handleChangeNoteResult: (sessionId: string, answerId: number, note: string) => void;
  hearingItem?: HearingItem;
  isLinkCreator: boolean;
  hideFeedbackSection: boolean;
  linkViewLogs: ViewingLog[];
};

const SharingLinkResultModal: FC<Props> = ({
  isModal,
  toggleModal,
  linkResults,
  title,
  activeSessionVisitId,
  productContents,
  submitSaveNote,
  handleChangeNoteResult,
  hearingItem,
  isLinkCreator,
  hideFeedbackSection,
  linkViewLogs,
}) => {
  const { t } = useTranslation();
  const activeResult = linkResults.find(result => result.session_visit_id === activeSessionVisitId);
  const activeLog = linkViewLogs.find(log => log.session_visit_id === activeSessionVisitId);

  const onChangeNoteResult = (answerId: number, note: string) => {
    if (activeResult) handleChangeNoteResult(activeResult.id, answerId, note);
  };

  function handleSaveNote() {
    if (activeResult) submitSaveNote(activeResult.id);
  }

  return (
    <Modal
      className="result-modal hearing_link"
      isModal={isModal}
      handleToggle={toggleModal}
      title={t('hearingPlay.result.questionnaireTitle')}
    >
      <LinkResultWrapper>
        <ResultContent>
          {activeResult && (
            <>
              {productContents ? (
                <ProductContentResult
                  result={activeResult || {}}
                  hideFeedbackSection={hideFeedbackSection}
                  viewingLogs={activeLog?.contents || []}
                />
              ) : (
                hearingItem && (
                  <HearingResult
                    title={title}
                    hearingSet={hearingItem}
                    handleChangeNote={onChangeNoteResult}
                    submitSaveNote={handleSaveNote}
                    isLinkCreator={isLinkCreator}
                    result={activeResult}
                    hideFeedbackSection={hideFeedbackSection}
                    viewingLogs={activeLog?.contents}
                  />
                )
              )}
              <Divider />
              <URLParams>
                <Title>{t('sharingLink.urlParameters').toUpperCase()}</Title>
                <ParamsDetail>{JSON.stringify(activeResult?.visit.ma_params)}</ParamsDetail>
              </URLParams>
            </>
          )}
        </ResultContent>
      </LinkResultWrapper>
    </Modal>
  );
};

export default SharingLinkResultModal;

const LinkResultWrapper = styled('div')({});

const ResultContent = styled('div')({
  padding: '20px 20px 20px 20px',
});

const Divider = styled('div')({
  margin: '20px 0',
  width: '100%',
});

const Title = styled('p')({
  fontSize: '1rem',
  color: palette.darkMedium2,
  marginBottom: 16,
});

const URLParams = styled('div')({
  fontSize: 14,
});

const ParamsDetail = styled('p')({
  wordBreak: 'break-all',
  lineHeight: 1.5,
});
