import { observable, action, toJS } from 'mobx';
import history from 'utility/history';
import shallowEqual from 'utility/shallowEqual';
import { RootStore } from '../index';
import { ProductItem } from 'types/Product';
import { MessageProps } from 'types/App';
import { BaseListParamsForFilterRequest, FilterByTagsParams } from 'types/common';
import ProductContentApi from 'services/ProductContentApi';
import { routes } from 'utility/constants';

const defaultProductDetail: ProductItem = {
  id: -1,
  content_type: 'videos',
  title: '',
  description: '',
  public_description: '',
  kaizen_files_url: '',
  kaizen_files_thumbnail_url: '',
  kaizen_files_bucket_id: '',
  kaizen_files_original_url: '',
  status: '',
  organization_id: 0,
  created_at: '',
  updated_at: '',
  kaizen_files_id: '',
  service: null,
  content_id: -1,
  service_resource_id_for_logger: '',
};

type ParamsToCompare = {
  page: number;
  order: string;
  sort: string;
  q?: string;
  types: string;
  tags: string;
};

class SalesProductListStore {
  private readonly productContentApi: ProductContentApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public sortType = '';
  @observable public productList: ProductItem[] = [];
  @observable public productDetail: ProductItem = { ...defaultProductDetail };
  @observable public productListTotal = 0;
  @observable public allProductList: ProductItem[] = [];
  @observable public searchKeyword = '';
  @observable public lastSearchParams: Partial<ParamsToCompare> | undefined;

  constructor({
    rootStore,
    productContentApi,
  }: {
    rootStore: RootStore;
    productContentApi: ProductContentApi;
  }) {
    this.rootStore = rootStore;
    this.productContentApi = productContentApi;
  }

  @action.bound
  public changeSearchKeyword(val: string) {
    this.searchKeyword = val;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getProductContentList({
    page = 1,
    order = 'updated_at',
    sort = 'desc',
    q = '',
    types = [],
    tags = [],
  }: Partial<BaseListParamsForFilterRequest & FilterByTagsParams>) {
    const searchParams = {
      page,
      order,
      sort,
      q,
      types: types.join(','),
      tags: tags.join(','),
    };

    if (this.lastSearchParams && !!shallowEqual(toJS(this.lastSearchParams), searchParams)) return;

    this.lastSearchParams = searchParams;
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.productContentApi.getProductContents(organization_id, {
        page,
        order,
        sort,
        per_page: 12,
        q,
        types,
        tags,
      });
      this.productList = data.product_contents;
      this.productListTotal = data.total;
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetProductList() {
    this.isLoading = false;
    this.productList = [];
    this.searchKeyword = '';
    this.lastSearchParams = undefined;
  }

  @action.bound
  public handleStartHearing(id: number, isRecording: boolean) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    if (isRecording) {
      history.push(
        `${routes.startAppointment}?content_id=${id}&organization=${organization_id}`,
        '_blank'
      );
    } else {
      window?.open(
        `${routes.startAppointment}?content_id=${id}&organization=${organization_id}`,
        '_blank'
      );
    }
  }
}

export default SalesProductListStore;
